/** @module application */

import { eventListenerOptions } from '@spinnwerk/polyfills';

/**
 * Manages the creation and visibility of the scroll-up-button.
 * @param {object} [o] - Initial Options.
 * @param {object} [o.classes]
 * @param {string} [o.classes.meter='scroll-up-meter'] - The name of the class of the element measuring the scrolled distance. Used as selector or is set to the created element, based on setting {@link o.createMeter}.
 * @param {string} [o.classes.button='scroll-up'] - The name of the class of the button triggering the scrolling to top. Used as selector or is set to the created element, based on setting {@link o.createButton}.
 * @param {boolean} [o.hideButtonParent] - Whether to hide only the button when applicable, or also its parent element. Useful when the button is e.g. inside a list with padded list items.
 * @param {HTMLElement|string|null} [o.appendButtonTo=null] - Element or selector of the element to where to append the created button to. If set to <code>null</code> (default), it is appended to the body element. If {@link o.createButton} is set to <code>false</code>, this setting has no effect.
 * @param {boolean} [o.createMeter=true] - Whether to create the element used to measure the scrolled distance. If setting this to <code>false</code>, ensure an element with a class name set in {@link o.classes.meter} exists, as it is mandatory. The reason for this element is to avoid scroll-event-listener but use the better performing {@link IntersectionObserver} instead. Use CSS to control the height of this element to manage when the button should be shown/hidden.
 * @param {boolean} [o.createTarget=true] - Whether to create the target element, that is a <code>span</code> with the id-attribute set to <code>top</code>. If setting this to <code>false</code, ensure an element with the id-attribute set to <code>top</code> exists at the top of the page, as it is mandatory.
 * @param {boolean} [o.createButton=false] - Whether to create the button triggering the scrolling to top. If letting this <code>false</code>, ensure a button with a class name set in {@link o.classes.button} exists, as it is mandatory.
 *
 * @requires module:@spinnwerk/polyfills
 */
export function scrollUp(o = {}) {
    const options = {
        ...{
            classes: { meter: 'scroll-up-meter', button: 'scroll-up' },
            hideButtonParent: true,
            appendButtonTo: null,
            createMeter: true,
            createTarget: true,
            createButton: false,
        },
        ...o,
    };

    if (options.createButton) {
        options.hideButtonParent = false; // do not hide the body element
    }

    let meter, button;

    if (options.createTarget) {
        let target = document.createElement('span');

        target.id = 'top';
        document.body.insertAdjacentElement('afterbegin', target);
    }

    if (options.createMeter) {
        meter = document.createElement('div');

        meter.classList.add(options.classes.meter);
        document.body.insertAdjacentElement('beforeend', meter);
    } else {
        meter = document.querySelector(`.${options.classes.meter}`);
    }

    if (options.createButton) {
        button = document.createElement('a');

        button.classList.add(options.classes.button);
        button.setAttribute('aria-hidden', 'true'); // hide the button from screen-readers as it has no value there
        button.href = '#top';
        button.dataset.setHash = 'false';

        if (options.appendButtonTo instanceof HTMLElement) {
            options.appendButtonTo.insertAdjacentElement('beforeend', button);
        } else if (typeof options.appendButtonTo === 'string') {
            document.querySelector(options.appendButtonTo).insertAdjacentElement('beforeend', button);
        } else {
            document.body.insertAdjacentElement('beforeend', button);
        }
    } else {
        button = document.querySelector(`.${options.classes.button}`);
    }

    if (meter && button) {
        let isIntersecting = false;

        const buttonParent = button.parentNode,
            observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    isIntersecting = entry.isIntersecting;

                    if (entry.isIntersecting) {
                        button.classList.remove('is-active');
                    } else {
                        if (options.hideButtonParent) {
                            buttonParent.classList.remove('hide');
                        }

                        button.classList.add('is-active');
                    }
                });
            });

        if (options.hideButtonParent) {
            button.addEventListener(
                'transitionend',
                () => {
                    if (isIntersecting) {
                        buttonParent.classList.add('hide');
                    }
                },
                eventListenerOptions({ passive: true }),
            );
        }

        observer.observe(meter);
    }
}
